<template>
  <div style="padding-top:20px;padding-bottom: 50px;">
    <el-row style="margin-top: 20px;">
      <el-col :md="{span:8,offset:8}">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="头像">
            <div class="avatarBut"
            @click="showCropper"
            >
              <el-avatar
              :size="80"
              :src="form.avatar"
              :key="form.avatar"
              ref="userAvatar"
              >
              </el-avatar>
            </div>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="用户名">
            <el-input v-model="form.username"
            :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <span>********</span>
            <span style="cursor: pointer;font-size: 13px;margin-left: 20px;
              color: #409EFF;
            "
            @click="passwordModel=true"
            >修改密码</span>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="form.sex" placeholder="请选择性别">
              <el-option v-for="sexItem in sexData" :key="sexItem.value":label="sexItem.name":value="sexItem.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="签名/介绍">
            <el-input
              placeholder="做一个简单的介绍吧"
              v-model="form.introduction"
              type="textarea"
              show-word-limit
              maxlength="100"
              :autosize="{
                minRows:2
              }"
              resize="none"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="form.email" placeholder="邮箱"></el-input>
          </el-form-item>
          <el-form-item label="手机">
            <el-input v-model="form.phone" placeholder="手机"></el-input>
          </el-form-item>
          <el-divider></el-divider>
          <el-button style="width: 100%;" type="primary" @click="saveUserInfo" :loading="saveLoading">保存</el-button>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog title="裁剪头像" :visible.sync="cropperModel" width="1000px"  center>
      <CropperImage
      :Name="cropperName"
      @uploadImgSuccess="handleUploadSuccess"
      ref="cropperChild"
      :Circle="true"
      UploadButName="点击上传"
      >
      </CropperImage>
    </el-dialog>
    <el-dialog title="密码" :visible.sync="passwordModel" width="400px"  center>
      <el-form v-model="updatePasswordForm" label-width="80">
        <el-form-item label="原密码">
          <el-input v-model="updatePasswordForm.password" placeholder="请输入原密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="updatePasswordForm.newPassword" placeholder="请输入新密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="updatePasswordForm.restPassword" placeholder="再次输入新密码" type="password"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-button style="width: 100%;" type="primary" :loading="updatePasswordLoading" @click="updatePassword">修改密码</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import CropperImage from "@/components/CropperImage"
import global from '@/api/global_variable.js'
import {updateSysUsersApi,updatePasswordApi} from '@/api/user'
import {getDictionaryListByTypeApi} from '@/api/data_dictionary'
import { removeToken,getToken } from '@/utils/token'
export default{
  name:'material',
  components: {
    CropperImage
  },
  created(){
    if(this.$route.query.username!=this.$store.state.user.user.username){
      this.$router.push({path: "/user", query: {username:this.$route.query.username}})
    }
    getDictionaryListByTypeApi("sex").then((res)=>{
      if(res.data.success){
        this.sexData=res.data.data
      }
    })
  },
  data(){
    return{
      saveLoading:false,
      updatePasswordLoading:false,
      updatePasswordForm:{},
      form:this.$store.state.user.user,
      sexData:[],
      imgURL:global.imgURL,
      cropperName:'',
      cropperModel:false,
      passwordModel:false,
    }
  },
  methods:{
    logout () {
      // logoutApi()
      removeToken()
      // 清除状态保持
      window.localStorage.clear()
      // 状态保持清除后刷新页面
      window.location.reload()
    },
    updatePassword(){
      this.updatePasswordLoading=true
      updatePasswordApi(this.updatePasswordForm).then((res)=>{
        if(res.data.success){
          this.$message({
            type:'success',
            message:'修改成功'
          })
          this.passwordModel=false
          this.logout()
        }else{
          this.$message({
            type:'warning',
            message:res.data.errorMsg
          })
        }
         this.updatePasswordLoading=false
      })
    },
    saveUserInfo(){
      this.updateSysUsers()
    },
    //图片上传成功后
    handleUploadSuccess(data) {
      this.form.avatar = data.url
      this.cropperModel = false
    },
    showCropper(){
      this.cropperModel = true
    },
    async updateSysUsers(){
      this.saveLoading=true
        const res=await updateSysUsersApi(this.form)
        if(res.data.success){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.$store.commit('user/updateUser', this.form)
          this.$emit('restUser',this.form.username)
          // this.logout()
        }else{
          this.$message({
            type:'error',
            message:res.data.errorMsg
          })
        }
        this.saveLoading=false
    }
  }
}

</script>

<style scoped>
.avatarBut{
  cursor: pointer;
  background-color: transparent;
  border-radius: 100%;
  padding: 0px;
  margin:0px;
  border:none;
}

</style>
