import request from '@/utils/request'

export function getDictionaryListByTypeApi(type){
  return request({
    url:'/dataDictionary/getDictionaryListByType',
    method:'get',
    useToken:true,
    params:{type}
  })
}
export function getAllDictionaryTypeApi(){
  return request({
    url:'/dataDictionary/getAllDictionaryType',
    useToken:true,
    method:'get'
  })
}

export function lockDictionaryApi(id){
  return request({
    url:'/dataDictionary/lockDictionary',
    method:'post',
    useToken:true,
    params:{id}
  })
}
export function unLockDictionaryApi(id){
  return request({
    url:'/dataDictionary/unLockDictionary',
    method:'post',
    useToken:true,
    params:{id}
  })
}
